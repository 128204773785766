import ThemeContext from '@contexts/ThemeContext'
import useLocalStorage from '@hooks/useLocalStorage'
import { PaletteMode } from '@mui/material'
import { DEFAULT_THEME } from '@utils/Config'
import React, { FC } from 'react'

const ThemeProvider: FC = ({ children }) => {
  const [mode, setMode] = useLocalStorage<PaletteMode>('theme', DEFAULT_THEME as PaletteMode)

  const toggleMode = () => {
    setMode(mode === 'light' ? 'dark' : 'light')
  }

  return (
    <ThemeContext.Provider
      value={{
        mode,
        toggleMode,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
