import { ThemeOptions } from '@mui/material'
import BreakpointsOptions from '@themes/CommonTheme/Breakpoints'
import CommonOptions from '@themes/CommonTheme/Common'
import ComponentOptions from '@themes/CommonTheme/Component'
import SpacingOptions from '@themes/CommonTheme/Spacing'
import TransitionOptions from '@themes/CommonTheme/Transition'
import TypographyOptions from '@themes/CommonTheme/Typography'

/**
 * Common theme options
 */
const CommonThemeOptions: ThemeOptions = {
  ...SpacingOptions,
  ...BreakpointsOptions,
  ...TypographyOptions,
  ...ComponentOptions,
  ...TransitionOptions,
  ...CommonOptions,
}

export default CommonThemeOptions
