import Snackbar from '@mui/material/Snackbar'
import React, { FC, useCallback, useState } from 'react'
import { GlobalSnackbarContext } from './context'
import { SnackbarVariables } from './interfaces'

const GlobalSnackbar: FC = ({ children }) => {
  const [variables, setVariables] = useState<SnackbarVariables | null>(null)

  const closeSnackbar = useCallback(() => {
    setVariables(null)
  }, [])

  const showMessage = useCallback((params: SnackbarVariables) => {
    setVariables(params)
  }, [])

  return (
    <>
      <GlobalSnackbarContext.Provider value={{ showMessage }}>{children}</GlobalSnackbarContext.Provider>

      {variables?.message && (
        <Snackbar
          open={true}
          onClose={closeSnackbar}
          message={variables.message}
          autoHideDuration={variables.duration || 3000}
          anchorOrigin={{
            horizontal: variables.horizontal || 'center',
            vertical: variables.vertical || 'top',
          }}
        />
      )}
    </>
  )
}

export default GlobalSnackbar
