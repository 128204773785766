import { RouteInterface } from '@interfaces/Route'
import { ROLE } from '@utils/Role'
import { lazy } from 'react'

/**
 * Lazy loading components
 */
const Home = lazy(() => import('@pages/Home'))
const Login = lazy(() => import('@pages/Login'))
const NotFound = lazy(() => import('@pages/NotFound'))
const Unauthorized = lazy(() => import('@pages/Unauthorized'))
const Register = lazy(() => import('@pages/Register'))
const ListUser = lazy(() => import('@pages/ListUser'))
const Chart = lazy(() => import('@pages/Chart'))
const UserDetails = lazy(() => import('@pages/UserDetails'))

/**
 * Routes definition
 */
const authenticatedRoutes: RouteInterface[] = [
  {
    key: 'home',
    path: '/home',
    isExact: true,
    isPrivate: true,
    roles: [ROLE.USER],
    component: Home,
  },
  {
    key: 'chart',
    path: '/chart',
    isExact: true,
    isPrivate: false,
    roles: [],
    component: Chart,
  },
  {
    key: 'chartWithTab',
    path: '/chart/:tabSelected',
    isExact: true,
    isPrivate: false,
    roles: [],
    component: Chart,
  },
  {
    key: 'userDetails',
    path: '/userDetails/:idClient',
    isExact: true,
    isPrivate: false,
    roles: [],
    component: UserDetails,
  },
  {
    key: 'list-user',
    path: '/list-user/:pageParam',
    isExact: true,
    isPrivate: true,
    roles: [ROLE.ADMIN],
    component: ListUser,
  },
  {
    key: 'notfound',
    path: '/notfound',
    isExact: true,
    isPrivate: false,
    component: NotFound,
  },
  {
    key: 'unauthorized',
    path: '/unauthorized',
    isExact: true,
    isPrivate: false,
    component: Unauthorized,
  },
]

const notAuthenticatedRoutes: RouteInterface[] = [
  {
    key: 'login',
    path: '/login',
    isExact: true,
    isPrivate: false,
    component: Login,
  },
  {
    key: 'register',
    path: '/register/:token',
    isExact: true,
    isPrivate: false,
    component: Register,
  },
  {
    key: 'notfound',
    path: '/notfound',
    isExact: true,
    isPrivate: false,
    component: NotFound,
  },
]

export { authenticatedRoutes, notAuthenticatedRoutes }
