import 'moment/min/locales'
import GlobalSnackbar from '@components/GlobalSnackbar'
import { useAppContext } from '@contexts/AppContext'
import useInitialAppLang from '@hooks/useInitialAppLang'
import useInitialAppTheme from '@hooks/useInitialAppTheme'
import AdapterMomentDate from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import FullPageLoading from '@pages/FullPageLoading'
import Router from '@routes/Router'
import React, { Suspense } from 'react'

const App = () => {
  useInitialAppLang()
  const { lang } = useAppContext()

  const theme = useInitialAppTheme()

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst={true}>
        <LocalizationProvider dateAdapter={AdapterMomentDate} locale={lang}>
          <GlobalSnackbar>
            <CssBaseline />
            <Suspense fallback={<FullPageLoading />}>
              <Router />
            </Suspense>
          </GlobalSnackbar>
        </LocalizationProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  )
}

export default App
