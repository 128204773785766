import PrivateRoute from '@routes/PrivateRoute'
import { authenticatedRoutes, notAuthenticatedRoutes } from '@routes/routes'
import { useTokenVerification } from '@services/AuthServices/verifyToken'
import { getToken, removeCredentials } from '@services/localStorageService'
import { ROLE } from '@utils/Role'
import React, { FC, useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

const Router: FC = () => {
  const token = getToken()

  const { verifyToken } = useTokenVerification()

  useEffect(() => {
    if (token) {
      verifyToken(token).then((validToken) => {
        if (!validToken) {
          removeCredentials()
          window.location.reload()
        }
      })
    }
  }, [token, verifyToken])

  const getRoutes = () => {
    if (token) {
      return authenticatedRoutes.map(({ key, path, component, isExact, isPrivate, roles }) =>
        isPrivate ? (
          <PrivateRoute key={key} exact={isExact} path={path} component={component} roles={roles || [ROLE.USER]} />
        ) : (
          <Route key={key} exact={isExact} path={path} component={component} />
        )
      )
    }
    return notAuthenticatedRoutes.map(({ key, path, component, isExact }) => (
      <Route key={key} exact={isExact} path={path} component={component} />
    ))
  }

  return (
    <BrowserRouter>
      <Switch>
        {getRoutes()}
        {token ? <Redirect to="/chart" /> : <Redirect to="/login" />}
      </Switch>
    </BrowserRouter>
  )
}

export default Router
