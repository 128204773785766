import { API_URL } from '@utils/Config'
import axios from 'axios'
import { useCallback } from 'react'

export const useTokenVerification = () => {
  const verifyToken = useCallback((token: string) => {
    return axios
      .get<{ status?: boolean } | undefined>(`${API_URL}api/v1/user/check-token`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => !!response.data?.status)
      .catch(() => false)
  }, [])

  return { verifyToken }
}
