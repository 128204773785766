import { createContext } from 'react'
import { SnackbarVariables } from './interfaces'

export interface GlobalSnackbarContextInterface {
  showMessage: (variables: SnackbarVariables) => void
}

export const GlobalSnackbarContext = createContext<GlobalSnackbarContextInterface>({
  showMessage: () => null,
})
