import AppContext from '@contexts/AppContext'
import useLocalStorage from '@hooks/useLocalStorage'
import { AppLang } from '@interfaces/AppLang'
import ThemeProvider from '@providers/ThemeProvider'
import { DEFAULT_LANG } from '@utils/Config'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const AppProvider: FC = ({ children }) => {
  const { i18n } = useTranslation()
  const [lang, setLang] = useLocalStorage<AppLang>('lang', DEFAULT_LANG as AppLang)

  const handleChangeLang = (language: AppLang) => {
    i18n.changeLanguage(language)
    setLang(language)
  }

  return (
    <AppContext.Provider
      value={{
        lang,
        setLang: handleChangeLang,
      }}
    >
      <ThemeProvider>{children}</ThemeProvider>
    </AppContext.Provider>
  )
}

export default AppProvider
