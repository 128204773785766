export const getToken = () => {
  const token = localStorage.getItem('token')
  return token
}

export const setToken = (token: string) => {
  localStorage.setItem('token', token)
}

interface IUser {
  id: number
  email: string
  username: string
  fullname: string
  roles: 'ROLE_ADMIN' | 'ROLE_USER'
}

export const setUser = (user: IUser) => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const getUser = () => {
  const isUser = localStorage.getItem('user')
  if (isUser) {
    return JSON.parse(isUser) as IUser
  }
  return null
}

export const removeCredentials = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
}
